body, html {
  padding: 0;
  margin: 0;
}

.container {
  max-width: 393px; /* Viewport width */
  margin: auto;
  overflow-x: hidden;
  min-height: 100vh; /* Full height of the viewport */
  display: flex;
  flex-direction: column;
}

.invisible-area {
  width: 30px; /* Define the size of the clickable area */
  height: 30px;
  cursor: pointer;
  opacity: 0; /* Make it invisible */
  background-color: white;
  position: absolute;
  top: 70%; /* Center the area for demonstration */
  left: 55%;
}

.anniversary-image {
  width: 100%;
  height: auto;
  display: block;
}

.spinning-text {
  position: absolute;
  top: 100px; /* Adjust this value to place the text closer to or further from the bottom edge */
  left: 10%;
  transform: translateX(-50%) rotate(0deg); /* Centers the text and starts rotation from center */
  width: 300px; /* Adjust size as needed */
  height: auto;
  animation: spin-text 3s infinite alternate ease-in-out;
}

.gift-button {
  position: absolute;
  bottom: 10%; /* Adjust this value based on your design preference */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #f76c6c;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}


@keyframes spin-text {
  from { transform: rotate(-10deg); }
  to { transform: rotate(10deg); }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 80%;
  border-radius: 20px;  /* 圆角的大小 */
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
  .gift-card {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 20px;  /* 圆角的大小 */
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
  }
  .close-button {
    position: absolute;
    top: 2px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    background: none;
    border: none;
    font-size: 24px;
    color: #aaa; /* Light gray for visibility, adjust as needed */
    cursor: pointer;
  }

  .close-button:hover {
    color: black; /* Change color on hover for better visibility */
  }
}
